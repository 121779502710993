
export enum AssetDoors {
  Single= 'Single',
  SingleSingle= 'SingleSingle',
  SingleDouble= 'SingleDouble',
  SingleSingleSingle= 'SingleSingleSingle',
  SingleDoubleSingle= 'SingleDoubleSingle',
  SingleDoubleDouble= 'SingleDoubleDouble',
  Double= 'Double',
  DoubleDouble= 'DoubleDouble',
  DoubleSingleSingle= 'DoubleSingleSingle',
  DoubleDoubleDouble= 'DoubleDoubleDouble',
  DoubleDoubleSingle= 'DoubleDoubleSingle',

}