import datetimeFormatEn from '~/i18n/datetime/en';
import datetimeFormatIt from '~/i18n/datetime/it';
import { defaultLocale } from '~/i18n/i18nConfig';
import en from '~/i18n/locales/en';
import it from '~/i18n/locales/it';
import numberFormatEn from '~/i18n/number/en';
import numberFormatIt from '~/i18n/number/it';

export default defineI18nConfig(() => ({
    legacy: false,
    locale: defaultLocale,
    messages: {
        en,
        it: it as typeof en,
    },
    datetimeFormats: {
        en: datetimeFormatEn,
        it: datetimeFormatIt as typeof datetimeFormatEn,
    },
    numberFormats: {
        en: numberFormatEn,
        it: numberFormatIt as typeof numberFormatEn,
    },
}));
